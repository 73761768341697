.blankImageWrapper {
  height: 100px;
  width: 100px;
  border: 2px solid gray;
  align-self: center;
  text-align: center;
  vertical-align: middle;
  line-height: 100px;
  margin-left: 20px;
}

.blankImageWrapper .addIcon {
  display: inline-block;
  color: gray;
  font-size: 20px;
  vertical-align: middle;
}

.imageDeleteBtn {
  position: absolute;
  left: 65px;
  top: -5px;
  z-index: 10;
}
.imageDeleteBtn .deleteIcon {
  font-size: 15px;
  color: red;
}

.imageWrapper {
  position: relative;
  height: 100px;
  width: 100px;
  border: 2px solid gray;
  line-height: 100px;
  margin-left: 20px;
  text-align: center;
}
